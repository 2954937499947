import React, { useState, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'customHooks/useUser';

import images from '../../themes/images';
import DropdownAction from './DropdownAction';
import UserInfo from './UserInfo';
import Image from './Image';
import LikeComment from './LikeComment';
import PostInfo from './PostInfo';
import CommentList from './Comment';
import ROUTERS from 'constants/router';
import ImagePreviewModal from './ImagesPreView';
import { useSelector } from 'react-redux';

// display: '1', '2'
function ListItem({
  item,
  omitHashtags = false,
  omitTitle = false,
  itemDisplayFormat = '1',
  wrapperClassName,
  editAction,
  deleteAction,
  showComment = false,
  showInfoUser = false,
  showBoxStore = false,
  timeAgo = false,
  highlight = '',
  param,
  highlightHashtagID = '',
  highlightStoreID = '',
}) {
  const history = useHistory();
  const user = useUser()
  const listComment = useRef(null);
  const [isShowComment, setShowComment] = useState(false);
  const [dataPreview, setDataPreview] = useState({
    isShow: false,
    img: '',
  });
  
  const onCommentClick = () => {
    if (showComment) {
      setShowComment((p) => !p);
    }
  };

  const _onClickDetail = () => {
    const url = ROUTERS.INQUIRY_PRODUCT_PAGE_DETAIL.replace(':id', item.id);
    const detailLink = itemDisplayFormat === '1' ? 'PLAYGROUND' : 'QNA';
    // history.push(`${url}?type=${detailLink}`);

    history.push({
      pathname: `${url}?type=${detailLink}`,
      state: param
    });

  };

  const _onClickPreview = (attachment) => {
    setDataPreview({
      isShow: true,
      img: attachment?.path_original || '',
    });
  };

  const hideModalView = () => {
    setDataPreview({
      isShow: false,
      img: '',
    });
  };

  const scrollTop = () => {
    if (listComment.current) {
      listComment.current.scrollTop = 0;
    }
  };
  

  return (
    <Fragment>
      <div
        className={`playground-list__item ${
          itemDisplayFormat !== '1' ? ' qna-item' : ' playground-item'
        }`}
      >
        <DropdownAction
          data={item}
          editAction={editAction}
          deleteAction={deleteAction}
          isShow={user.uid === item.user_id}
        />
        <UserInfo
          user={item.user}
          data={item}
          createdAt={item.created_at}
          onClick={_onClickDetail}
          timeAgo={timeAgo}
          highlight={highlight}
          itemDisplayFormat={itemDisplayFormat}
        />

        {itemDisplayFormat !== '1' ? (
          <div>
            {item?.attachments && item?.attachments.length > 0 && (
              <Image
                images={item.attachments}
                className="w-100 m-0"
                itemDisplayFormat={itemDisplayFormat}
                detailLink={() => _onClickPreview(item.attachments[0])}
              />
            )}

            <PostInfo
              data={item}
              hashtags={item.hashtags}
              omitHashtags={omitHashtags}
              omitTitle={omitTitle}
              onCommentClick={onCommentClick}
              itemDisplayFormat={itemDisplayFormat}
              detailLink={_onClickDetail}
              timeAgo={timeAgo}
              highlight={highlight}
              highlightHashtagID={highlightHashtagID}
              highlightStoreID={highlightStoreID}
            />
            {/*<LikeComment />*/}
          </div>
        ) : (
          <div className="item-content-post">
            <PostInfo
              data={item}
              hashtags={item.hashtags}
              omitHashtags={omitHashtags}
              omitTitle={omitTitle}
              onCommentClick={onCommentClick}
              showBoxStore={true}
              // omitUser
              detailLink={_onClickDetail}
              timeAgo={timeAgo}
              highlight={highlight}
              highlightHashtagID={highlightHashtagID}
              highlightStoreID={highlightStoreID}
            />
            <Image images={item?.attachments} itemDisplayFormat={itemDisplayFormat} detailLink={_onClickDetail} />
            {/*<LikeComment />*/}
          </div>
        )}
      </div>
      {isShowComment && (
        <div className="comment comment-in-list" ref={listComment}>
          <CommentList
            data={item}
            userInfo={user}
            showFormCreate
            dateTimeFormat={'Y.MM.DD HH:mm:ss'}
            noDataMessage="아직 작성된 댓글이 없어요."
            // type={'QNA'}
            // scrollTop={scrollTop}
          />
        </div>
      )}

      {/* Popup view image */}
      {itemDisplayFormat !== '1' && (
        <ImagePreviewModal
          data={dataPreview.img}
          isShow={dataPreview.isShow}
          setIsShowModalView={hideModalView}
        />
      )}
    </Fragment>
  );
}

export default ListItem;
