
import React, { useState, useEffect } from "react";
import NoData from "components/NoData";
import ROUTERS from "constants/router";
import MainLayout from "layout/MainLayout";
import { useHistory } from "react-router-dom";
import { API, ROUTES } from "utils/Apis";
import { formatDate } from "utils/Helpers";
import { TIME_EVENT_NEW_MEMBER, TIME_EVENT_LOTTERY, TIME_EVENT_HASTAG, EVENT_TYPE } from "constants/common";
import moment from "moment";
import LazyLoadImg from "components/LazyLoadImg";
import SkeletonLoadingComponent from "components/SkeletonLoading";
import imgNewMember from '../../../assets/images/new_member_event.png';
import imgLottery from '../../../assets/images/lottery_event.png';
import imgHashtag from '../../../assets/images/hashtag_event.png';

import imgNewMemberWeb from '../../../assets/images/new_member_event@3x.webp';
import imgLotteryWeb from '../../../assets/images/lottery_event@3x.webp';
import imgHashtagWeb from '../../../assets/images/hashtag_event@3x.webp';

import { checkStatusCodeApi } from "../../../utils/Helpers";

type Props = {
  userInfo: any,
  token: String
};

const now = moment();

let dataEvent = process.env.REACT_APP_EVENT === 'true' ? [
  {
    id: 1,
    event_name: '신규회원 가입 이벤트',
    start_time: TIME_EVENT_NEW_MEMBER.START,
    end_time: TIME_EVENT_NEW_MEMBER.END,
    image: imgNewMemberWeb,
    status: now.diff(moment(TIME_EVENT_NEW_MEMBER.START), 'seconds') >= 0 && now.diff(moment(TIME_EVENT_NEW_MEMBER.END), 'seconds') <= 0 ? 'active' : 'inactive',
    type: EVENT_TYPE.NEW_MEMBER,
  },
  {
    id: 2,
    event_name: '복권 증정 이벤트',
    start_time: TIME_EVENT_LOTTERY.START,
    end_time: TIME_EVENT_LOTTERY.END,
    image: imgLotteryWeb,
    status: now.diff(moment(TIME_EVENT_LOTTERY.START), 'seconds') >= 0 && now.diff(moment(TIME_EVENT_LOTTERY.END), 'seconds') <= 0 ? 'active' : 'active',
    type: EVENT_TYPE.RECEIVE_LOTTERY,
  },
  {
    id: 3,
    event_name: '해시태그 이벤트',
    start_time: TIME_EVENT_HASTAG.START,
    end_time: TIME_EVENT_HASTAG.END,
    image: imgHashtagWeb,
    status: now.diff(moment(TIME_EVENT_HASTAG.START), 'seconds') >= 0 && now.diff(moment(TIME_EVENT_HASTAG.END), 'seconds') <= 0 ? 'active' : 'inactive',
    type: EVENT_TYPE.HASH_TAG,
  },
] : [];

const EventComponent = ({
  userInfo,
  token,
}:Props) => {

  const history = useHistory();
  const [dataEvents, setDataEvents] = useState(dataEvent);
  const [loadingStatusEvent, setLoadingStatusEvent] = useState(true);
  const [loadingImg, setLoadingImg] = useState(true);
  const [paramEvents, setParamEvents] = useState({
    type: 'all',
    page: 1
  });

  
  const FILTER_TYPE = {
    'all' : '전체', 
    'active' : '진행중', 
    'inactive' : '종료'
  }; 

  // get data lottery of user
  const fetchStatusEvent = async (page = 1) => {
    try {
      const { data: response } = await API.get(
        ROUTES.API_GET_EVENT_STATUS
      );
      // eslint-disable-next-line no-underscore-dangle
      const _dataEvent = [...dataEvents];
      console.log(response, 'response');
      if (response?.code !== 200) {
        checkStatusCodeApi(response.code);
      }
      _dataEvent.map((item, idx) => {
        if (item.type === EVENT_TYPE.NEW_MEMBER) {
          if (item.status === 'active') {
            _dataEvent[idx].status = response.data?.[EVENT_TYPE.NEW_MEMBER]?.isEnable ? 'active' : 'inactive'
          }
        }
        if (item.type === EVENT_TYPE.RECEIVE_LOTTERY) {
          if (item.status === 'active') {
            _dataEvent[idx].status = response.data?.[EVENT_TYPE.RECEIVE_LOTTERY]?.isEnable ? 'active' : 'inactive';
            _dataEvent[idx].end_time = response.data?.[EVENT_TYPE.RECEIVE_LOTTERY]?.isEnable ? '2023-10-15 23:59:59' : '2023-10-10 23:59:59'
          }
        }
      })
      setDataEvents(_dataEvent);
      dataEvent = [..._dataEvent];
      setLoadingStatusEvent(false);
    } catch (error) {
      console.error(error.message);
      setLoadingStatusEvent(false);
    }
  };

  useEffect(() => {
    fetchStatusEvent();
  }, [])

  useEffect(() => {
    if (paramEvents.type !== 'all') {
      const dataMap = dataEvent.filter(item => item.status === paramEvents.type);
      setDataEvents(dataMap);
    }else{
      setDataEvents(dataEvent);
    }
  }, [paramEvents])


  const goToDetail = (event) => {
    if (event.status === 'inactive') return false; 
    const url = ROUTERS.EVENT_DETAIL.replace(':id', event.type);
    // history.push(`${url}?token=${token}`);
    history.push(`${url}?type=web-view`, {
      nickname: userInfo?.nickname || ''
    });
  }

  const handleClickType = (type) => {
    setParamEvents({
      ...paramEvents,
      type
    })
  }

  const renderFilterBtn = () => {

    return Object.keys(FILTER_TYPE).map((item, idx) => {
      return (
        <div
          className={`btn-filter ${paramEvents.type === item ? 'active' : ''}`}
          key={idx}
          onClick={() => handleClickType(item)}
        >
          <span>{FILTER_TYPE[item]}</span>
        </div>
      );
    });
  };
  
  const handleAfterLoad = (e) => {
    setLoadingImg(false);
  }

  const renderEvents = (data) => {
    console.log(data, 'renderEvents');
    if (data && data.length > 0) {
      return data.map(item => {
        return (
          <div 
            className="event-page__content__event-item" 
            key={item.id} 
            onClick={() => goToDetail(item)}
          >
            <div className={`${!loadingImg ? 'box-img ' : ''}`}>
              {
                loadingStatusEvent ? <>
                <SkeletonLoadingComponent cssClass="border-img" width={'100%'} height={197}/>
                </> : <>
                  <LazyLoadImg src={item.image} className='event-img' handleAfterLoad={(e) => handleAfterLoad(e)} height='100%'/>
                    {
                      loadingImg ? <SkeletonLoadingComponent cssClass="border-img" width={'100%'} height={197}/> : 
                        item.status === 'inactive' && <>
                          <div className="marker" />
                          <h2 className="text">
                            종료된 이벤트
                          </h2>
                        </>
                    }
                </>
              }
            </div>
            
            <div className="note">
              <p className="event-name">
                {item.event_name || ''}
              </p>
              <div className="event-note">
                <span className="event-time-title">기간</span>
                <span className="event-space" />
                {/* {
                  item.type === "NEW_MEMBER" ? <span className="event-time-title">상시</span> : <>
                    <span className="event-time">{formatDate(item.start_time, '', 'Y. MM. DD')} </span>
                    <span className="event-time space" >~</span>
                    <span className="event-time"> {formatDate(item.end_time,'', 'Y. MM. DD')}</span>
                  </>
                } */}
                <span className="event-time">{formatDate(item.start_time, '', 'Y. MM. DD')} </span>
                <span className="event-time space" >~</span>
                <span className="event-time"> {formatDate(item.end_time,'', 'Y. MM. DD')}</span>
              </div>
            </div>
          </div>
        )
      })
    }
    return <NoData text="종료된 이벤트가 없습니다."/>;
  }

  return (
    <MainLayout
      customClass="MainPage"
      isShowHeader
      titleHeader="이벤트"
      icoBackWhite
      isShowIcon
      isLink
      isBackNative
      isLoading={false}
    >
      <div className="event-page">
        <div className="filter-form">
          {renderFilterBtn()}
        </div>
        <div className="event-page__content">
          {renderEvents(dataEvents)}
        </div>
      </div>
    </MainLayout>
  )     
    
}

export default EventComponent;